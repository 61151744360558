import React, {useContext, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleLeft, faEnvelope, faUnlockAlt} from "@fortawesome/free-solid-svg-icons";
import {faFacebookF, faGithub, faTwitter} from "@fortawesome/free-brands-svg-icons";
import {Col, Row, Form, Card, Button, FormCheck, Container, InputGroup} from '@themesberg/react-bootstrap';
import {Link} from 'react-router-dom';

import {Routes} from "../../routes";
import BgImage from "../../assets/img/illustrations/signin.svg";
import {AuthContext} from "../../contexts/auth-context";
import {useHistory} from 'react-router-dom';

export default () => {
    const [email, setEmail] = useState("");
    const auth = useContext(AuthContext);
    let history=useHistory();
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const login = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            if (email == "admin@admin.com" && password == "123456") {
                auth.login({first_name: "admin", last_name: "admin", email: "admin@admin.com"}, "Bearer 123456");
                history.push('/');
            } else {

            }
        }, 2000);
    }
    return (
        <main>
            <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
                <Container>
                    {/* <p className="text-center">
                        <Card.Link as={Link} to={Routes.DashboardOverview.path} className="text-gray-700">
                            <FontAwesomeIcon icon={faAngleLeft} className="me-2"/> Back to homepage
                        </Card.Link>
                    </p>*/}
                    <Row className="justify-content-center form-bg-image" style={{backgroundImage: `url(${BgImage})`}}>
                        <Col xs={12} className="d-flex align-items-center justify-content-center">
                            <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                                <div className="text-center text-md-center mb-4 mt-md-0">
                                    <h3 className="mb-0">Sign in to our platform</h3>
                                </div>
                                <div className="mt-4">
                                    <Form.Group id="email" className="mb-4">
                                        <Form.Label>Your Email</Form.Label>
                                        <InputGroup>
                                            <InputGroup.Text>
                                                <FontAwesomeIcon icon={faEnvelope}/>
                                            </InputGroup.Text>
                                            <Form.Control onChange={(e) => setEmail(e.target.value)} autoFocus required
                                                          type="email"
                                                          value={email}
                                                          placeholder="example@company.com"/>
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Group id="password" className="mb-4">
                                            <Form.Label>Your Password</Form.Label>
                                            <InputGroup>
                                                <InputGroup.Text>
                                                    <FontAwesomeIcon icon={faUnlockAlt}/>
                                                </InputGroup.Text>
                                                <Form.Control onChange={(e) => setPassword(e.target.value)} required
                                                              type="password" placeholder="Password"/>
                                            </InputGroup>
                                        </Form.Group>
                                        {/* <div className="d-flex justify-content-between align-items-center mb-4">
                      <Form.Check type="checkbox">
                        <FormCheck.Input id="defaultCheck5" className="me-2" />
                        <FormCheck.Label htmlFor="defaultCheck5" className="mb-0">Remember me</FormCheck.Label>
                      </Form.Check>
                       <Card.Link className="small text-end">Lost password?</Card.Link>
                    </div>
                    */}
                                    </Form.Group>
                                    <Button onClick={login} variant="primary" type="submit" className="w-100">
                                        Sign in
                                    </Button>
                                </div>


                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </main>
    );
};
