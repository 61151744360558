import {createContext} from 'react';

export const AuthContext = createContext({
    user: null,
    isLoggedIn: false,
    token: null,
    userId: null,
    login: () => {
    },
    logout: () => {
    },
    update: () => {
    },
})
