import React, {useState} from 'react';
import {Row, Col, Card, Modal, Button, Container} from '@themesberg/react-bootstrap';


const CustomModal = ({title, show, handleClose, ...props}) => {


    return (

        <Modal as={Modal.Dialog} centered show={show} onHide={handleClose}>
            <Modal.Header>
                <Modal.Title className="h6">{title}</Modal.Title>
                <Button variant="close" aria-label="Close" onClick={handleClose}/>
            </Modal.Header>
            <Modal.Body>
                {props.children}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Fermer
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CustomModal;
