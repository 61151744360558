import React, {useState, useEffect, useContext} from 'react';
import {Route, Switch, Redirect, BrowserRouter} from "react-router-dom";
import {Routes} from "../routes";

// pages
import Presentation from "./Presentation";
import Upgrade from "./Upgrade";
import DashboardOverview from "./dashboard/DashboardOverview";

import LoginView from "./../views/login/LoginView";

import Lock from "./examples/Lock";
import NotFoundPage from "./examples/NotFound";
import ServerError from "./examples/ServerError";

// documentation pages
import DocsOverview from "./documentation/DocsOverview";
import DocsDownload from "./documentation/DocsDownload";
import DocsQuickStart from "./documentation/DocsQuickStart";
import DocsLicense from "./documentation/DocsLicense";
import DocsFolderStructure from "./documentation/DocsFolderStructure";
import DocsBuild from "./documentation/DocsBuild";
import DocsChangelog from "./documentation/DocsChangelog";

// components
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Preloader from "../components/Preloader";

import Accordion from "./components/Accordion";
import Alerts from "./components/Alerts";
import Badges from "./components/Badges";
import Breadcrumbs from "./components/Breadcrumbs";
import Buttons from "./components/Buttons";
import Forms from "./components/Forms";
import Modals from "./components/Modals";
import Navs from "./components/Navs";
import Navbars from "./components/Navbars";
import Pagination from "./components/Pagination";
import Popovers from "./components/Popovers";
import Progress from "./components/Progress";
import Tables from "./components/Tables";
import Tabs from "./components/Tabs";
import Tooltips from "./components/Tooltips";
import Toasts from "./components/Toasts";
import {AuthContext} from "../contexts/auth-context";
import InvoicesListView from "../views/invoices/InvoicesListView";

const RouteWithLoader = ({component: Component, ...rest}) => {
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => setLoaded(true), 1000);
        return () => clearTimeout(timer);
    }, []);

    return (
        <Route {...rest} render={props => (<> <Preloader show={loaded ? false : true}/> <Component {...props} /> </>)}/>
    );
};

const RouteWithSidebar = ({component: Component, ...rest}) => {
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => setLoaded(true), 1000);
        return () => clearTimeout(timer);
    }, []);

    const localStorageIsSettingsVisible = () => {
        return localStorage.getItem('settingsVisible') === 'false' ? false : true
    }

    const [showSettings, setShowSettings] = useState(localStorageIsSettingsVisible);

    const toggleSettings = () => {
        setShowSettings(!showSettings);
        localStorage.setItem('settingsVisible', !showSettings);
    }

    return (
        <Route {...rest} render={props => (
            <>
                <Preloader show={loaded ? false : true}/>
                <Sidebar/>

                <main className="content">
                    <Navbar/>
                    <Component {...props} />
                    <Footer toggleSettings={toggleSettings} showSettings={showSettings}/>
                </main>
            </>
        )}
        />
    );
};

export default () => {
    const auth = useContext(AuthContext);
    return auth.token ?

        <Switch>
            <RouteWithSidebar exact path={'/'} component={InvoicesListView}/>
            <RouteWithLoader exact path={Routes.Lock.path} component={Lock}/>
            <RouteWithLoader exact path={Routes.ServerError.path} component={ServerError}/>
            {/*<RouteWithSidebar exact path={Routes.DashboardOverview.path} component={DashboardOverview}/>*/}
            <RouteWithSidebar exact path={Routes.Invoices.path} component={InvoicesListView}/>

            <Route component={NotFoundPage} />
        </Switch>

        :

        <Switch>

            <RouteWithLoader exact path={Routes.Signin.path} component={LoginView}/>
            <Redirect to={Routes.Signin.path}/>

        </Switch>


}
