import {useState, useCallback, useEffect, useMemo} from "react";
//let logoutTimer;
export const useAuth = () => {

    const [token, setToken] = useState(null);
    const [userId, setUserId] = useState();
    const [user, setUser] = useState(null);
    // const [tokenExp, setTokenExp] = useState();


    const login = useCallback((user, token) => {
        setToken(token);
        setUser(user);
        // const tokenExpDate = expDate || new Date(new Date().getTime() + 1000 * 60 * 60);
        //setTokenExp(tokenExpDate);
        localStorage.setItem('userData', JSON.stringify({
            user,
            token: token,
        }))
    }, []);


    const update = useCallback((user) => {
        setUser(user);
        let userData = JSON.parse(localStorage.getItem('userData'));
        userData.user = user;
        localStorage.setItem('userData', JSON.stringify(userData))
    }, []);

    const logout = useCallback(() => {
        setToken(null);
        setUser(null);
        localStorage.removeItem('userData');
    }, []);


    useMemo(() => {
        const userData = JSON.parse(localStorage.getItem('userData'));
        if (userData && userData.token) {
            login(userData.user, userData.token);
        }
    }, []);
    return {token, userId, login, logout, user, update};
}
