import {useAuth} from "./hooks/auth-hook";
import {BrowserRouter, HashRouter} from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import HomePage from "./pages/HomePage";
import React from "react";
import {AuthContext} from "./contexts/auth-context";
import {ToastProvider, useToasts} from 'react-toast-notifications';

const App = () => {
    const auth = useAuth();
    return <BrowserRouter>
        <ToastProvider>
            <AuthContext.Provider value={auth}>
                <ScrollToTop/>
                <HomePage/>
            </AuthContext.Provider>
        </ToastProvider>
    </BrowserRouter>
}
export default App;
