import React, {Component} from 'react';
import axios from 'axios';
import apiService from '../../../services/API';
import Progress from "../../../components/Progress";


export default class InvoiceDocumentUpload extends Component {
    constructor(props) {
        super(props);

        this.state = {
            file: "",
            progress: 0,
            loading: false,
            currentMessage: "",
            responseMsg: {
                status: "",

                message: "",
                error: "",
            },
        };
    }

    // image onchange hander
    handleChange = (e) => {
        this.setState({
            file: e.target.files[0]
        })
    }

    // submit handler
    submitHandler = (e) => {
        e.preventDefault();
        if (!this.state.file)
            return;
        const data = new FormData()
        data.append('file', this.state.file)
        this.setState({loading: true,});

        apiService.post("invoices_documents/add", data, {
            onUploadProgress: progressEvent => {
                let percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
                if (!isNaN(percentCompleted)) {
                    if (percentCompleted == 100) {
                        this.setState({
                            progress: 100,
                            currentMessage: "Parsing the invoice document in progress",
                        });
                    } else {
                        this.setState({progress: percentCompleted});
                    }
                }
            }
        })
            .then((response) => {

                console.table(response);
                if (response.data.status === 200) {
                    this.props.updateData(response.data.data)
                }

                this.setState({
                    currentMessage: "",
                    progress: 0,
                    file: "",
                    loading: false,
                    responseMsg: {
                        status: response.data.status,
                        message: response.data.message,
                    },
                });


                document.querySelector("#fileForm").reset();


            })
            .catch((error) => {
                this.setState({progress: 0, loading: false});
                console.error(error);
            });
    }

    render() {
        return (

            <div className="row">
                <div className="">
                    {!this.state.loading &&
                    <form onSubmit={this.submitHandler} encType="multipart/form-data" id="fileForm">
                        <div className="card shadow">
                            {this.state.responseMsg.status === "successs" ? (
                                <div className="alert alert-success">
                                    {this.state.responseMsg.message}
                                </div>
                            ) : this.state.responseMsg.status === "failed" ? (
                                <div className="alert alert-danger">
                                    {this.state.responseMsg.message}
                                </div>
                            ) : (
                                ""
                            )}
                            <div className="card-header">
                                <h4 className="card-title fw-bold">

                                </h4>
                            </div>

                            <div className="card-body">
                                <div className="form-group py-2">
                                    <label htmlFor="images">File</label>
                                    <input
                                        type="file"
                                        name="file"
                                        onChange={this.handleChange}
                                        className="form-control"
                                    />
                                    <span className="text-danger">
                      {this.state.responseMsg.error}
                    </span>
                                </div>
                            </div>

                            <div className="card-footer">
                                <button type="submit" className="btn btn-success">
                                    Upload
                                </button>
                            </div>
                        </div>
                    </form>
                    }
                    {this.state.loading &&
                    <Progress variant="primary"
                              label={this.state.currentMessage ? this.state.currentMessage : "Envoie en cours"}
                              value={this.state.progress}/>
                    }
                    <span className={'text-success'}>{this.state.responseMsg?.message}</span>
                </div>


            </div>
        );
    }
}
