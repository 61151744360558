import React, {useEffect, useState} from 'react';
import apiService from '../../services/API';
import MUIDataTable from "mui-datatables";

import {Button} from '@themesberg/react-bootstrap';
import Modal from "../../pages/components/CustomModal/CustomModal";
import InvoiceDocumentUpload from "../../pages/components/InvoiceDocumentUpload/InvoiceDocumentUpload";
import {Table} from '@themesberg/react-bootstrap';

const InvoicesListView = () => {

    const columns = [
        {
            name: 'fields',
            label: 'fields',
            options: {
                filter:false,
                display: false,
            }
        },
        {
            name: 'id',
            label: 'id',
            options: {
                filter:false,
                display: false,
            }
        },
        {
            name: 'name',
            label: 'Nom',
        },
        {
            name: 'type',
            label: 'Type',
            options: {
                customBodyRender: (value, tableMeta) => (
                    value == 1 ? "Document" : "Image"
                )
            }
        },
        {
            name: 'status',
            label: 'Status',
            options: {
                customBodyRender: (value, tableMeta) => (
                    "Reviewing"
                )
            }
        },
        {
            name: 'user',
            label: 'Upload By',
            options: {
                filter:false,
                customBodyRender: (value, tableMeta) => (
                    value?.first_name + " " + value?.last_name
                )
            }
        },
        {
            name: 'created_at',
            label: 'Créer le '
        },
        {
            name: 'updated_at',
            label: 'Modifier le ',
        },
        {
            name: 'actions',
            label: 'Action',

            options: {
                filter:false,
                customBodyRender: (value, tableMeta) => (
                    <Button title={'Consulter les attributs'} onClick={() => {
                        setSelectedFields(tableMeta.rowData[0]);
                        setShowModal(true);
                    }}>
                        Consulter les attributs
                    </Button>
                )
            }
        },

    ]

    const [invoiceDocuments, setInvoiceDocuments] = useState([]);
    const [invoice, setInvoice] = useState(null);
    const [selectedFields, setSelectedFields] = useState(null);
    const [showModal, setShowModal] = useState(false);
    useEffect(() => {
        apiService.post('invoices_documents/list', {}).then(async (res) => {
            setInvoiceDocuments(res.data.invoices_list);
        }).catch(async (error) => {
            console.log("error : " + error);
        });
    }, []);

    const addFile = () => {

    }

    const updateData = (data) => {
        setInvoiceDocuments(invoiceDocuments.concat(data));
    }

    return <div>
        <Button style={{marginBottom:20}} onClick={() => setShowModal(true)}>Ajouter un document</Button>
        <MUIDataTable columns={columns} data={invoiceDocuments}/>
        <Modal show={showModal} handleClose={() => {
            setShowModal(false);
            setSelectedFields(null)
        }} title={selectedFields ? "Attributs" : 'Ajouter un document'}>

            {!selectedFields ?
                <InvoiceDocumentUpload updateData={updateData}/>
                :
                <ul>
                    {selectedFields?.map((field) => (
                        <li className="border-0">{field.name} : {field.value}</li>
                    ))}
                </ul>
            }
        </Modal>
    </div>

}
export default InvoicesListView;
