import React from 'react';
import axios from 'axios';

export class ApiService {

    static environment = 'prod';

    prodIp = '127.0.0.1:8888/projects/OCRInvoiceSystemWS/public/';

    devApiUrl = 'http://' + this.prodIp + 'api';

    devWebUrl = 'http://' + this.prodIp;

    jwtDevApi = 'http://' + this.prodIp + 'api';

    prodApiUrl = 'https://ocrapi.shinra.com.tn/api';

    jwtProdApi = 'https://ocrapi.shinra.com.tn/api';

    prodWebUrl = 'https://ocrapi.shinra.com.tn/';

    checkEnv(jwt = false) {

        switch (ApiService.environment) {
            case 'dev':
                return !jwt ? this.devApiUrl : this.jwtDevApi;
            case 'prod':
                return !jwt ? this.prodApiUrl : this.jwtProdApi;
            default:
                return '';
        }
    }

    checkEnvWebUrl() {

        switch (ApiService.environment) {
            case 'dev':
                return this.devWebUrl;
            case 'prod':
                return this.prodWebUrl;
            default:
                return '';
        }

    }

    post(endpoint, body, config = {}) {
        const headers = {
            'Content-Type': 'application/json',
            ...config,
        };

        body.token = (localStorage.appState != null) ? JSON.parse(localStorage.appState).user.auth_token : null;
        return axios.post(this.checkEnv() + '/' + endpoint, body, headers);
    }


}


const instance = new ApiService();

export default instance;
